:root {
    --color-primary: #00325B;
    --color-primary-rgb: 6, 128, 229;
    --color-primary-contrast: #ffffff;
    --color-primary-contrast-rgb: 255, 255, 255;
    --color-primary-shade: #00213c;
    --color-primary-tint: #1f8de8;

    --color-secondary: #3dc2ff;
    --color-secondary-rgb: 61, 194, 255;
    --color-secondary-contrast: #ffffff;
    --color-secondary-contrast-rgb: 255, 255, 255;
    --color-secondary-shade: #36abe0;
    --color-secondary-tint: #50c8ff;

    --color-tertiary: #5260ff;
    --color-tertiary-rgb: 82, 96, 255;
    --color-tertiary-contrast: #ffffff;
    --color-tertiary-contrast-rgb: 255, 255, 255;
    --color-tertiary-shade: #4854e0;
    --color-tertiary-tint: #6370ff;

    --color-success: #34c96d;
    --color-success-rgb: 52, 201, 109;
    --color-success-contrast: #000000;
    --color-success-contrast-rgb: 0, 0, 0;
    --color-success-shade: #2eb160;
    --color-success-tint: #48ce7c;

    --color-warning: #ffc409;
    --color-warning-rgb: 255, 196, 9;
    --color-warning-contrast: #000000;
    --color-warning-contrast-rgb: 0, 0, 0;
    --color-warning-shade: #e0ac08;
    --color-warning-tint: #ffca22;

    --color-danger: #ff5050;
    --color-danger-rgb: 255, 80, 80;
    --color-danger-contrast: #000000;
    --color-danger-contrast-rgb: 0, 0, 0;
    --color-danger-shade: #e04646;
    --color-danger-tint: #ff6262;

    --color-dark: #222428;
    --color-dark-rgb: 34, 36, 40;
    --color-dark-contrast: #ffffff;
    --color-dark-contrast-rgb: 255, 255, 255;
    --color-dark-shade: #1e2023;
    --color-dark-tint: #383a3e;

    --color-medium: #92949c;
    --color-medium-rgb: 146, 148, 156;
    --color-medium-contrast: #ffffff;
    --color-medium-contrast-rgb: 255, 255, 255;
    --color-medium-shade: #808289;
    --color-medium-tint: #9d9fa6;

    --color-light: #f4f5f8;
    --color-light-rgb: 244, 245, 248;
    --color-light-contrast: #000000;
    --color-light-contrast-rgb: 0, 0, 0;
    --color-light-shade: #d7d8da;
    --color-light-tint: #f5f6f9;

    --color-menu: #164973;
    --color-menu-rgb: 22, 73, 115;
    --color-menu-contrast: #ffffff;
    --color-menu-contrast-rgb: 255, 255, 255;
    --color-menu-shade: #134065;
    --color-menu-tint: #2d5b81;

    --color-font-register: #00213c;

    --color-font: #2F2F2F;

    --color-light: #EEF0F2;
    --color-light-shade: #00000049;

}